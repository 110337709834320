import { defineCustomElement } from 'vue'
import HelloWorld from '@/CustomElements/Hello/HelloWorld.ce.vue'
import Individual from '@/CustomElements/Individual/Individual.ce.vue'

const baseTag = `pwc-lead-`

// convert into custom element constructor
const HelloCE = defineCustomElement(HelloWorld)
const IndividualCE = defineCustomElement(Individual)

// register
customElements.define(`${baseTag}hello`, HelloCE)
customElements.define(`${baseTag}individual`, IndividualCE)

