import axios from 'axios'
import { Lead } from '../Models/Classes/Lead.class'
import { LeadRoutes } from '../Models/Enums/ApiLeadEnum'

const axiosConnection = axios.create({
    baseURL: process.env.VUE_APP_LEAD_URL,
    timeout: 10000,
    headers: {
        'accept': 'application/json',
        'x-ParlemApiKey': process.env.VUE_APP_LEAD_API_KEY
    }
})
const LeadApi = {

    /**
     * PUT Create Lead
     * @lead Lead
     * @returns 
     */
    async create(lead: Lead) {
        const params = lead.DTO()
        return await axiosConnection.post(`${LeadRoutes.createC2c}`, params)
            .then(response => {
                return response.data
            })
    },
}

export default LeadApi