
import i18nService from "@/Domain/Services/i18n.service"
import GTM from "@/Domain/Services/gtm.service"
import { defineComponent } from 'vue'
import LeadApi from '@/Domain/Services/lead.service'
import { Lead } from '@/Domain/Models/Classes/Lead.class'
import { Checkbox } from '@/Domain/Models/Classes/Checkbox.class'
import { LeadSubmited } from '@/Domain/Events/LeadSubmited'

 export default defineComponent({
    name: 'Individual',
    props: {
        config: String,
    },
    data() {
        return this._initialState()
    },
    mounted() {
        if (!this.loaders.translation) {
            this.wcConfig = this._parseProps()
            i18nService.init(this.t, this.language).then(()=>{
                this.loaders.translation = true;
            })
        }
    },
    methods: {
        _initialState() {
            return {
                WatchName: '',
                WatchPhone: '',
                WatchEmail: '',
                WatchTerms: false,
                formData: {
                    name: {
                        isValid: true,
                        isRequired: true,
                    },
                    email: {
                        isValid: true,
                        isRequired: true,
                    },
                    phone: {
                        isValid: true,
                        isRequired: true,
                    },
                    time: {
                        isValid: true,
                        isRequired: false
                    },
                },
                lead: new Lead(),
                wcConfig: {
                    theme: 'Parlem',
                    language: 'ca',
                    formFieldList: [],
                    checkboxList: new Array<Checkbox>(), // {'name', 'text', 'state': true | false, 'required': true | false}
                    leadSettings: {
                        tradeCompany: 'Parlem',
                        trademark: 'Parlem',
                        parameter: {
                            key: 'lead',
                            value: '1'
                        },
                        campaign: 'c2cleadsweb',
                        pack: '',
                    },
                    campaign: 'c2cleadsweb',
                    pack: ''
                },
                loaders: {
                    form: false,
                    translation: false,
                },
                language: 'ca',
                t: i18nService.getInstance(),
            }
        },
        _parseLang() {
            const config = JSON.parse(this.config)
            return config.language
        },
        _parseProps() {
            // Config validator
            const result = {
                'theme': 'Parlem',
                'language': 'ca',
                'formFieldList': [],
                'checkboxList': new Array<Checkbox>(), // {'name', 'text', 'state': true | false, 'required': true | false}
                'leadSettings': {
                    'tradeCompany': 'Parlem',
                    'trademark': 'Parlem',
                    'parameter': {
                        'key': 'lead',
                        'value': '1'
                    },
                    'campaign': 'c2cleadsweb',
                    'pack': ''
                },
            }
            const config = JSON.parse(this.config)
            if (config.theme) result.theme = config.theme
            if (config.checkboxList) {
                result.checkboxList.push({
                    name: "privacy-terms",
                    linkTxt: "lookup-link",
                    state: false,
                    url: "https://parlem.com/avis-de-privacitat/",
                    isRequired: true
                })
            } 
            if (config.formFieldList) result.formFieldList = result.formFieldList.concat(config.formFieldList)
            this.language = config.language ? config.language : this.language
            result.leadSettings.pack = config.leadSettings?.pack ? config.leadSettings.pack : result.leadSettings.pack
            result.leadSettings.campaign = config.leadSettings?.campaign ? config.leadSettings.campaign : result.leadSettings.campaign
            result.leadSettings.parameter = config.leadSettings?.parameter ? config.leadSettings?.parameter : result.leadSettings.parameter
            return result
        },
        _resetForm() {
            this.WatchName = '',
            this.WatchPhone = '',
            this.WatchEmail = '',
            this.wcConfig.checkboxList.forEach((ch:any) => ch.state = false)
            this.WatchTerms = false,
            this.lead = new Lead()
            this.formData = {
                    name: {
                        isValid: true,
                        isRequired: true,
                    },
                    email: {
                        isValid: true,
                        isRequired: true,
                    },
                    phone: {
                        isValid: true,
                        isRequired: true,
                    },
                    time: {
                        isValid: true,
                        isRequired: false
                    },
                }
        },
        _checkForm() {
            // check fields
            let okFields = true
            this.wcConfig.formFieldList.forEach( (field:any) => {
                if (!this._validateField(field) && okFields) okFields = false
            });
            // check checkbox list
            const okCheckBox = this._validateCheckBoxList(this.wcConfig.checkboxList)
            return okFields && okCheckBox
        },
        _validateField(fieldName: string) : boolean {
            let ok = true
            switch( fieldName ) {
                case 'name':
                    if ( this.formData.name.isRequired ) {
                        ok = (this.lead.name.length > 0)
                    }
                    this.formData.name.isValid = ok
                    break;
                case 'email':
                    // eslint-disable-next-line
                    var emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                    if ( this.formData.email.isRequired ) {
                        ok = (emailRegex.test(this.lead.email))
                    }
                    this.formData.email.isValid = ok
                    break;
                case 'phone':
                    // eslint-disable-next-line
                    var phoneRegex = /^\+?(6\d{2}|7[1-9]\d{1})\d{6}$/
                    if ( this.formData.phone.isRequired ) {
                        ok = (phoneRegex.test(this.lead.phoneNumber))
                    }
                    this.formData.phone.isValid = ok
                    break;
                default:
                    break;
            }
            return ok
        },
        _validateCheckBoxList(chBoxList: Array<any>) : boolean {
            let ok = true
            chBoxList.forEach( (chbox: any) => {
                if (chbox.isRequired) {
                    if (!chbox.state) {
                        chbox.isInvalid = true
                        if ( ok ) ok = false
                    }
                    else chbox.isInvalid = false
                }
            })
            return ok
        },
        processForm() {
            if (this._checkForm()) {
                this.loaders.form = true
                this.lead.updateSettings(this.wcConfig.leadSettings)
                LeadApi.create(this.lead)
                .then((data:any) => {
                    const payload = {
                        status: 200,
                        msg: 'lead submited'
                    }
                    LeadSubmited.getInstance().emitCustomEvent(payload)
                    GTM.pushC2C(this.lead.pack)
                    this._resetForm()
                }).catch((e:any) => {
                    this.errorMsg = "Error."
                    console.error('Error:',e)
                    const payload = {
                        status: 200,
                        msg: 'lead submited'
                    }
                    LeadSubmited.getInstance().emitCustomEvent(payload)
                }).finally(() => {
                    this.loaders.form = false
                })
            }
        }
    },
    watch: {
        /* check each letter while typing in AddressInput*/
        WatchName: function (e:any) {
            this.lead.name = e
            this.formData.name.isValid = true
        },
        WatchPhone: function (e:any) {
            this.lead.phoneNumber = e
            this.formData.phone.isValid = true
        },
        WatchEmail: function (e:any) {
            this.lead.email = e
            this.formData.email.isValid = true
        },
    }
 })
