import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17be477a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "field-group --column"
}
const _hoisted_2 = ["placeholder", "aria-invalid"]
const _hoisted_3 = { class: "field-label" }
const _hoisted_4 = {
  key: 1,
  class: "field-group --column"
}
const _hoisted_5 = ["placeholder", "aria-invalid"]
const _hoisted_6 = { class: "field-label" }
const _hoisted_7 = {
  key: 2,
  class: "field-group --column"
}
const _hoisted_8 = ["placeholder", "aria-invalid"]
const _hoisted_9 = { class: "field-label" }
const _hoisted_10 = {
  key: 3,
  class: "field-group"
}
const _hoisted_11 = { disabled: "" }
const _hoisted_12 = { value: "0" }
const _hoisted_13 = { value: "1" }
const _hoisted_14 = { value: "2" }
const _hoisted_15 = { value: "3" }
const _hoisted_16 = { class: "field-group" }
const _hoisted_17 = ["onUpdate:modelValue"]
const _hoisted_18 = { for: "checkbox.name" }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "field-group pwc-flex --center" }

export function render(_ctx, _cache) {
  return (_ctx.loaders.translation)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.wcConfig.theme)
      }, [
        _createElementVNode("form", {
          class: "form-group",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers((...args) => (_ctx.processForm && _ctx.processForm(...args)), ["prevent"]))
        }, [
          (_ctx.wcConfig.formFieldList.includes('name'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.WatchName) = $event)),
                  placeholder: _ctx.t.t('name-placeholder'),
                  "aria-invalid": !_ctx.formData.name.isValid
                }, null, 8, _hoisted_2), [
                  [_vModelText, _ctx.WatchName]
                ]),
                _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t.t('name-placeholder')), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(["error", {show: !_ctx.formData.name.isValid}])
                }, _toDisplayString(_ctx.t.t('field-required')), 3)
              ]))
            : _createCommentVNode("", true),
          (_ctx.wcConfig.formFieldList.includes('phone'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.WatchPhone) = $event)),
                  placeholder: _ctx.t.t('phone-placeholder'),
                  "aria-invalid": !_ctx.formData.phone.isValid
                }, null, 8, _hoisted_5), [
                  [_vModelText, _ctx.WatchPhone]
                ]),
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.t.t('phone-placeholder')), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(["error", {show: !_ctx.formData.phone.isValid}])
                }, _toDisplayString(_ctx.t.t('field-required')), 3)
              ]))
            : _createCommentVNode("", true),
          (_ctx.wcConfig.formFieldList.includes('email'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.WatchEmail) = $event)),
                  placeholder: _ctx.t.t('email-placeholder'),
                  "aria-invalid": !_ctx.formData.email.isValid
                }, null, 8, _hoisted_8), [
                  [_vModelText, _ctx.WatchEmail]
                ]),
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.t.t('email-placeholder')), 1),
                _createElementVNode("span", {
                  class: _normalizeClass(["error", {show: !_ctx.formData.email.isValid}])
                }, _toDisplayString(_ctx.t.t('field-required')), 3)
              ]))
            : _createCommentVNode("", true),
          (_ctx.wcConfig.formFieldList.includes('time'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.lead.timeSlot) = $event))
                }, [
                  _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.t.t('timeslot-disabled')), 1),
                  _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.t.t('timeslot-call-now')), 1),
                  _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.t.t('timeslot-9-13')), 1),
                  _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.t.t('timeslot-13-17')), 1),
                  _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.t.t('timeslot-17-21')), 1)
                ], 512), [
                  [_vModelSelect, _ctx.lead.timeSlot]
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wcConfig.checkboxList, (checkbox) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: "checkbox.name",
                "onUpdate:modelValue": $event => ((checkbox.state) = $event)
              }, null, 8, _hoisted_17), [
                [_vModelCheckbox, checkbox.state]
              ]),
              _createElementVNode("label", _hoisted_18, [
                _createTextVNode(_toDisplayString(_ctx.t.t(checkbox.name)) + " ", 1),
                _createElementVNode("a", {
                  href: checkbox.url,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, _toDisplayString(_ctx.t.t(checkbox.linkTxt)), 9, _hoisted_19)
              ]),
              _createElementVNode("span", {
                class: _normalizeClass(["error", {show: checkbox.isInvalid}])
              }, _toDisplayString(_ctx.t.t('field-required')), 3)
            ]))
          }), 256)),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("button", {
              type: "submit",
              class: _normalizeClass({'primary': true,'ripple': true, 'loading': _ctx.loaders.form})
            }, _toDisplayString(_ctx.t.t('btn-send')), 3)
          ])
        ], 32)
      ], 2))
    : _createCommentVNode("", true)
}