declare global {
    interface Window { dataLayer: any; }
}

const GTM = {
    async pushC2C(item: any) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Et-truquem',
            'item_name': item || 'c2c',
        });
        console.info('GTM.service.ts', window.dataLayer)
    },
}

export default GTM