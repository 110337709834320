import { iEvent } from '@/Domain/Models/Interfaces/iEvent'
import { EventEnum } from '@/Domain/Models/Enums/EventEnum'

export class LeadSubmited implements iEvent {

    private static instance: LeadSubmited

    public name: string;

    constructor() {
        this.name = EventEnum.LeadSubmited
    }

    /**
     * controls the access to the singleton instance
     * @returns the instance
     */
    public static getInstance(): LeadSubmited {
        if (!LeadSubmited.instance) {
            LeadSubmited.instance = new LeadSubmited();
        }

        return LeadSubmited.instance;
    }

    /**
     * send data JSON Object to the DOM
     * @param payload is ?
     */
    public emitCustomEvent(payload: any): void {
        const customEvent = new CustomEvent(this.name, {
            detail: payload,
            bubbles: true,
            composed: true
        })
        window.dispatchEvent(customEvent)
    }
}