import { TimeSlot } from "../Enums/TimeSlot.enum";

export class Lead {
    name = '';
    lastName = '';
    comments = '';
    phoneNumber = '';
    email = '';
    documentNumber = '';
    company = '';
    timeSlot = 0;
    competitorCompany = '';
    datePermanence = '';

    customerType = '';
    campaign = '';
    pack = '';
    originalPage = '';
    parameter = {
        key: '',
        value: ''
    }
    tradeCompany = '';
    trademark = '';

    DTO(): any {
        const body: any = {
            name: this.name,
            phoneNumber: this.phoneNumber.toString(),
            timeSlot: this._getTimeSlot(),
            email: this.email,
            tradecompany: this.tradeCompany,
            trademark: this.trademark,
            pack: this.pack,
            campaign: this.campaign,
            customerType: this.customerType,
        }
        if (this.parameter.value != "0") {
            body.parameter = this.parameter
        }
        return body
    }
    updateSettings(settings: any) {
        this.tradeCompany = settings.tradeCompany || null
        this.trademark = settings.trademark || null
        this.pack = settings.pack || null
        this.campaign = settings.campaign || null
        this.customerType = settings.customerType || null
        this.originalPage = settings.originalPage || null
        this.parameter.key = settings.parameter?.key || null
        this.parameter.value = settings.parameter?.value || null
        console.info(this)
    }
    private _getTimeSlot() {
        const times = [TimeSlot.default, TimeSlot.X09_to_12, TimeSlot.X13_to_17, TimeSlot.X18_to_21]
        return times[this.timeSlot]
    }
}