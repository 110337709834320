import { createStore } from "vuex";
import { iState } from '@/Domain/Models/Interfaces/iState'

export default createStore<iState>({
  state: {
    count: 0
  },
  mutations: {
    increment(state: iState) {
      state.count++;
    }
  },
});