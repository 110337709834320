
/**
 * This component is an example of Custom Element, and using the MutationStorage
 */
import { defineComponent } from 'vue';
import { computed } from "vue";
import store from "@/Domain/Store/store";
// import packageJson from './package.json'
import {version, name} from '@/../package.json';

export default defineComponent({
  name: 'HelloWorld',
  setup() {
    const count = computed(() => store.state.count);
    const handleClickMutation = () => store.commit("increment");
    const title = name
    return {
      title,
      version,
      store,
      count,
      handleClickMutation,
    };
  },
});
