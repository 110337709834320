import i18next from 'i18next';
import HttpApi from 'i18next-http-backend'; // fallback http load

const i18nService = {
    getInstance() {
        return i18next.createInstance()
    },
    async init(instance: any, locale: string) {
        await instance.use(HttpApi)
            .init({
                initImmediate: false,
                debug: process.env.NODE_ENV != 'production',
                fallbackNS: 'translation',
                defaultNS: 'trasnlation',
                fallbackLng: locale,
                supportedLngs: ['en', 'es', 'ca'],
                load: 'all',
                backend: {
                    loadPath: `${process.env.VUE_APP_TRANSLATION_URL}pwc-lead/{{lng}}.json`,
                },


            })
        return instance
    },
}



export default i18nService;